import { getImageProps } from 'next/image';
import NextLink from 'next/link';
import { useMedia } from 'react-use';
import { UmbracoTypes } from '~/lib/data-contract';
import { ButtonAsLink } from '~/shared/components/Button';
import { umbracoLoader } from '~/shared/components/Image/loaders/umbracoLoader';
import { RawHtml } from '~/shared/components/RawHtml';
import { Spacer } from '~/shared/components/Spacer';
import { Video } from '~/shared/components/Video';
import { AlignContent } from '~/templates/blocks/shared/AlignContent';
import { Headline } from '~/templates/blocks/shared/Headline';
import { breaks, queries } from '~/theme/breakpoints';
import { useModule } from '../../hooks/useModule';
import { useModuleTheme } from '../../hooks/useModuleTheme';
import { ModuleContainer } from '../../shared/ModuleContainer';
import getFocalPoint from '../../utils/getFocalPoint';
import { StyledM75, StyledM75Content, StyledMediaArea } from './styled';

export type M75MediaTextProps = UmbracoTypes.IM75MediaAndTextModule;

export const M75MediaText = ({
    image,
    video,
    headline,
    description,
    callToAction,
    mediaSize = 'default',
    mediaHorizontalAlignment = 'left',
    horizontalAlignment = 'left',
    verticalAlignment = 'center',
    backgroundColor: initialBackgroundColor,
    backgroundColorHEX: initialBackgroundColorHex,
    callToActionTypeFill,
    hasGap = true,
    maxHeight = UmbracoTypes.M75MediaModuleMaxHeight.Medium,
    imageMobile,
    ...rest
}: M75MediaTextProps) => {
    const { index } = useModule();
    const { textColor, backgroundColor, buttonColor, buttonVariant } = useModuleTheme({
        backgroundColor: initialBackgroundColorHex || initialBackgroundColor,
        buttonVariant: callToActionTypeFill ? 'fill' : 'stroke',
    });

    const mediaType = video ? 'video' : 'image';

    const isTabletUp = useMedia(queries.md, false);

    const {
        props: { srcSet: desktop, ...desktopProps },
    } = getImageProps({
        loader: umbracoLoader,
        alt: image?.alt ?? 'Media Text Image Desktop',
        src: image?.src ?? '',
        fill: true,
        sizes: `${breaks.pageMax}px`,
        loading: index < 2 ? 'eager' : 'lazy',
        style: {
            objectFit: 'cover',
            objectPosition: getFocalPoint(image),
        },
    });

    const {
        props: { srcSet: mobile, ...mobileProps },
    } = getImageProps({
        loader: umbracoLoader,
        fill: true,
        alt: imageMobile?.alt ?? 'Media Text Image Mobile',
        src: imageMobile?.src ?? '',
        loading: index < 2 ? 'eager' : 'lazy',
        sizes: '80vw',
        style: {
            objectFit: 'cover',
            objectPosition: getFocalPoint(imageMobile),
        },
    });

    return (
        <ModuleContainer hasGutter {...rest}>
            <StyledM75>
                <StyledM75Content
                    backgroundColor={backgroundColor}
                    mediaSize={mediaSize}
                    mediaAlignment={mediaHorizontalAlignment}
                    mediaType={mediaType}
                    hasGap={hasGap}
                    maxHeight={maxHeight}
                >
                    <AlignContent
                        alignment={horizontalAlignment}
                        position={verticalAlignment}
                        textColor={textColor}
                    >
                        {headline ? <Headline variant="display2">{headline}</Headline> : null}
                        {headline ? <Spacer size="4" /> : null}
                        {description ? <RawHtml html={description} /> : null}
                        {callToAction ? <Spacer size="2" customFrom={{ '1000px': '4' }} /> : null}
                        {callToAction ? (
                            <NextLink href={callToAction.url} passHref legacyBehavior>
                                <ButtonAsLink
                                    target={callToAction.target}
                                    variant={buttonVariant}
                                    color={buttonColor}
                                >
                                    {callToAction.text}
                                </ButtonAsLink>
                            </NextLink>
                        ) : null}
                    </AlignContent>
                    <StyledMediaArea mediaType={mediaType}>
                        <picture>
                            <source
                                media={imageMobile?.src ? `${queries.md}` : ''}
                                srcSet={desktop}
                            />
                            {imageMobile?.src && <source srcSet={mobile} />}
                            <img {...(isTabletUp ? desktopProps : mobileProps)} />
                        </picture>
                        {mediaType === 'video' && video ? (
                            <Video
                                controls={false}
                                src={video.src}
                                loop={video.loop}
                                playing={video.autoPlay}
                                posterSrc={image?.src}
                            />
                        ) : null}
                    </StyledMediaArea>
                </StyledM75Content>
            </StyledM75>
        </ModuleContainer>
    );
};
